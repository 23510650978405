<template>
  <div class="about">
    <div class="aboutPage">
      <!-- menu -->
      <div class="menu">
        <div class="left">
          <img src="/assets/home/logo.png" @click="home" />
          <span @click="home">HOME</span>
          <span @click="about">ABOUT</span>
          <span @click="stake">STAKE</span>
        </div>
        <div class="right">
          <img src="/assets/home/opensea.png" @click="opensea"/>
          <img src="/assets/home/twitter.png" @click="twitter" />
          <img src="/assets/home/game.png" @click="game" />
          <!-- <img src="/assets/home/share.png" /> -->
        </div>
      </div>
      <div class="section1">
        <div class="left"><img src="/assets/about/iqiyi.png" /></div>
        <div class="right">
          <div class="title">iQIYI</div>
          <div class="con">
            Founded in 2010, iQIYI is an innovative market-leading online
            entertainment service in ChinaAccording to its Q3 2022 financial
            results, iQIYI currently has an average daily subscribing members of
            101 million, making it the leading internet video streaming service
            in China. On YouTube, the subscriber number of iQIYI’s channels has
            also exceeded 10 millions.
          </div>
        </div>
        <div class="link" @click="iqiyi">
          <div class="top">Website >></div>
          <div class="oimg"><img src="/assets/about/arrow.png" /></div>
        </div>
      </div>
      <div class="section2">
        <div class="right">
          <div class="title">OHDAT</div>
          <div class="con">
            OHDAT Labs was founded in May 2021 to work with top creators or IPs
            to build the Web3 ecosystem for them through creativity, technology
            and bridging the gap between web2 and web3, physical and digital,
            past and future. OHDAT has successfully secured a combined funding
            of $4 million currently from Future capital, Hashkey Capital, y2z
            Ventures, Innoangel Fund and Odaily founder Mandy Wang.
          </div>
        </div>
        <div class="left"><img src="/assets/about/ohdat.png" /></div>
        <div class="link" @click="ohdat">
          <div class="top">Website >></div>
          <div class="oimg"><img src="/assets/about/arrow.png" /></div>
        </div>
      </div>
      <div class="footer">
        <div class="odiv">AEAEA Technology Ltd.</div>
        <div class="fixdiv">
          <span>© 2022 Producer C All Right Reserved</span>
          <img src="/assets/home/opensea.png" @click="opensea" class="opensea"/>
          <img
            src="/assets/home/twitter.png"
            @click="twitter"
            class="twitter"
          />
          <img src="/assets/home/game.png" @click="game" />
        </div>
      </div>
    </div>
    <div class="m_about">
      <div class="aboutLogo">
        <img src="/assets/about/iqiyi.png" v-if="actives == 0" class="oimg" />
        <img src="/assets/about/ohdat.png" v-else class="ohdat" />
      </div>
      <van-tabs v-model="actives" swipeable>
        <van-tab info="iQIYI">
          <div class="content">
            Founded in 2010, iQIYI is an innovative market-leading online
            entertainment service in ChinaAccording to its Q3 2022 financial
            results, iQIYI currently has an average daily subscribing members of
            101 million, making it the leading internet video streaming service
            in China. On YouTube, the subscriber number of iQIYI’s channels has
            also exceeded 10 millions.
            <div class="link" @click="iqiyi">
              <div class="top">Website >></div>
              <div class="oimg"><img src="/assets/about/arrow.png" /></div>
            </div>
          </div>
        </van-tab>
        <van-tab info="OHDAT">
          <div class="content">
            OHDAT Labs was founded in May 2021 to work with top creators or IPs
            to build the Web3 ecosystem for them through creativity, technology
            and bridging the gap between web2 and web3, physical and digital,
            past and future. OHDAT has successfully secured a combined funding
            of $4 million currently from Future capital, Hashkey Capital, y2z
            Ventures, Innoangel Fund and Odaily founder Mandy Wang.
            <div class="link" @click="ohdat">
              <div class="top">Website >></div>
              <div class="oimg"><img src="/assets/about/arrow.png" /></div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs } from "vant";
import "vant/lib/index.css";

export default {
  name: "iqiyi",
  data() {
    return {
      actives: 0,
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    twitter() {
      window.open("https://twitter.com/producerc_nft");
    },
    game() {
      window.open("https://discord.com/invite/u8YSQd7UhP");
    },
    home() {
      this.$router.push("/");
    },
    about() {
      this.$router.push("/about");
    },
    stake(){
      this.$router.push("/stake");
    },
    ohdat() {
      window.open("https://www.ohdat.io/");
    },
    iqiyi() {
      window.open("https://www.iq.com/");
    },
    opensea() {
      window.open("https://opensea.io/collection/producerc");
    },
  },
  beforeDestroy() {},
};
</script>
<style src="./index.scss" lang="scss" scoped></style>
<style src="./index.mobile.scss" lang="scss" scoped></style>